import { BaseApi, ApiResult } from '@/services/BaseApi';
import * as ApiInterfaces from '@/models/Interfaces';

class RefDataApi extends BaseApi {
    public async getRegions(): Promise<ApiResult<ApiInterfaces.Region[]>> {
        return this.get<ApiInterfaces.Region[]>('api/regions');
    }

    public async getCities(): Promise<ApiResult<ApiInterfaces.City[]>> {
        return this.get<ApiInterfaces.City[]>('api/cities');
    }

    public async getProductTypes(): Promise<ApiResult<ApiInterfaces.ProductType[]>> {
        return this.get<ApiInterfaces.ProductType[]>('api/product-types');
    }
    public async getTags(): Promise<ApiResult<ApiInterfaces.Tag[]>> {
        return this.get<ApiInterfaces.Tag[]>('api/tags');
    }
    public async getSubTags(): Promise<ApiResult<ApiInterfaces.SubTag[]>> {
        return this.get<ApiInterfaces.SubTag[]>('api/sub-tags');
    }
    public async getAllLangs(): Promise<ApiResult<ApiInterfaces.Language[]>> {
        return this.get<ApiInterfaces.Language[]>('api/languages');
    }
    public async getPredefinedDates(): Promise<ApiResult<ApiInterfaces.PredefinedDate[]>> {
        return this.get<ApiInterfaces.PredefinedDate[]>('api/predefined-dates');
    }
    public async getPriceTypes(): Promise<ApiResult<ApiInterfaces.PriceType[]>> {
        return this.get<ApiInterfaces.PriceType[]>('api/price-types');
    }
    public async getMyProducts(): Promise<ApiResult<ApiInterfaces.IdText[]>> {
        return this.get<ApiInterfaces.IdText[]>('api/my-products');
    }
    public async getProviderCoupons(): Promise<ApiResult<ApiInterfaces.IdTextClient[]>> {
        return this.get<ApiInterfaces.IdTextClient[]>('api/provider-coupons');
    }
    public async getChannels(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/channels');
    }
    public async getBanks(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/banks');
    }
    public async getBankBranches(): Promise<ApiResult<ApiInterfaces.BankBranch[]>> {
        return this.get<ApiInterfaces.BankBranch[]>('api/bank-branches');
    }
    public async getBusinessClientTypes(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/businessClientTypes');
    }
    public async getOfficialClubs(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/official-clubs');
    }
    public async getCountries(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/countries');
    }
    public async getAccounts(): Promise<ApiResult<ApiInterfaces.Account[]>> {
        return this.get<ApiInterfaces.Account[]>('api/getAccounts');
    }
    public async getProviderUsers(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/getProviderUsers');
    }
    public async getProviderAccounts(): Promise<ApiResult<ApiInterfaces.ProviderAccountObject[]>> {
        return this.get<ApiInterfaces.ProviderAccountObject[]>('api/providerAccounts');
    }
}

export const refDataApi = new RefDataApi();
