import _ from 'lodash';
import { Composer, VueI18n } from 'vue-i18n';
const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export default function addKendoLocale(i18n: Composer<any, any, any, any>) {
    const { te, t, locale } = i18n;
    function td(key: string, def: string) {
        return te(key) ? t(key) : def;
    }
    const en = kendo.cultures['en-US'];
    const currentLang = _.cloneDeep(en);
    kendo.cultures['bookit'] = currentLang;
    if (currentLang.calendar) {
        const short = currentLang.calendar.days.namesShort.map(x => x.toLowerCase());
        currentLang.calendar.days.names = short.map(x => t('weekdayfull.' + x));
        currentLang.calendar.days.namesAbbr = short.map(x => t('weekdayshort.' + x));
        currentLang.calendar.days.namesShort = short.map(x => t('weekdayshort.' + x));
        currentLang.calendar.months.names = monthList.map(x => td('month.names.' + x, x));
        currentLang.calendar.months.namesAbbr = monthList.map(x => x.substr(0, 3)).map(x => td('month.short.' + x, x));
    }
    kendo.culture('bookit');
}
