import { DocumentType, InvoiceType } from '@/models/Enums';

export class DocumentsFilter {
    customer: string;
    number: string | null;
    keyword: string;
    pageIndex: number;
    createDateStart: string | null;
    createDateEnd: string | null;
    type: number | null;
    orderNumber: string | null;
    orderBy: string;
    asc: boolean;
    orderId: string;
    providerDocumentTypeId: string | null;
    creatorName: string;
    amount: string | null;
    currency: string | null;

    constructor() {
        this.customer = '';
        this.number = null;
        this.keyword = '';
        this.pageIndex = 0;
        this.createDateStart = null;
        this.createDateEnd = null;
        this.type = null;
        this.orderNumber = null;
        this.orderBy = 'Number';
        this.asc = true;
        this.orderId = '';
        this.providerDocumentTypeId = null;
        this.creatorName = '';
        this.amount = null;
        this.currency = null;
    }
}

export class Document {
    id: string;
    orderId: string;
    documentType: DocumentType | null;
    invoiceType: InvoiceType | null;
    createDate: string;
    createdBy: string;
    documentNumber: number;
    creator: string;
    customer: string;
    pdfUrl: string;
    providerDocumentTypeName: string;
    total: number;
    amountBeforeVat: number;
    vat: number;
    businessClientName: string;
    currency: string;
    businessClientId: number | null;
    issueDate: string;

    constructor() {
        this.id = '';
        this.orderId = '';
        this.documentType = null;
        this.invoiceType = null;
        this.createDate = '';
        this.createdBy = '';
        this.documentNumber = 0;
        this.creator = '';
        this.customer = '';
        this.pdfUrl = '';
        this.providerDocumentTypeName = '';
        this.total = 0;
        this.amountBeforeVat = 0;
        this.vat = 0;
        this.businessClientName = '';
        this.currency = '';
        this.businessClientId = null;
        this.issueDate = '';
    }
}

export class CancelReceiptResponse {
    receiptId: string;
    orderNumber: string;

    constructor() {
        this.receiptId = '';
        this.orderNumber = '';
    }
}
