import { StoreOptions, ActionContext } from 'vuex';
import {
    InitResponse,
    GeneralState,
    Language,
    Currency,
    CurrencyRate,
    PriceType,
    Region,
    ProductType,
    AverageDurationTime,
    Permissions,
    IdText,
    CustomerToChangeDate,
    IdName,
    DefaultProviderSettings
} from '@/models/Interfaces';
import { ProductFilter, Product, ProductPrice, ProductRegion, ProductTags, ProductAllTags, OrdersNotifications } from '@/models/Product';
import { OrderDetails, OrderProductDetails, OrderProductCustomerDetails, OrdersFilter, Slot } from '@/models/Order';
import { refDataApi } from '@/services/RefDataApi';
import { DocumentsFilter } from '@/models/Document';
import { Dates, Day } from '@/models/Calendar';
import { useI18n } from 'vue-i18n';
import { BusinessClientsFilter, BusinessAccountingFilter, BusinessOrderProductsFilter } from '@/models/BusinessClients';
import { CouponFilter, ProviderSettingsData } from '@/models/Provider';
import _ from 'lodash';
import moment from 'moment';
import { Employee } from '@/models/Employee';
import { api } from '@/services/Api';
import q from 'q';
import { ApiResult } from '@/services/BaseApi';
import { AwaitBulkInvoicesResponse, SnoozeReminderResponse } from '@/models/Interfaces';
import swal from 'sweetalert2';
import { SubCouponsFilter } from '@/models/Coupons';
import { LocalStorageKeys } from '@/models/LocalStorageKeys';
import { UserReminder } from '@/models/CrmCustomers';

const storeState: GeneralState = new GeneralState();
async function awaitTask(
    action: () => Promise<ApiResult<AwaitBulkInvoicesResponse>>,
    onSuccess: (bulkInvoiceHistoryId: string) => void,
    onFail: () => void,
    waitTime = 3000
) {
    await q.delay(waitTime);
    const resp = await action();
    if (resp.errorMessage) {
        swal.fire({
            icon: 'error',
            text: resp.errorMessage
        });
        onFail();
        return;
    }
    if (resp.data?.bulkInvoiceHistoryId) {
        onSuccess(resp.data.bulkInvoiceHistoryId);
        return;
    }

    if (waitTime > 0) {
        awaitTask(action, onSuccess, onFail);
    }
}
function storeWaitForExportTaskSilent(context: ActionContext<GeneralState, GeneralState>, taskId: string, actionName: string) {
    context.commit(actionName, { taskId });
    if (taskId) {
        awaitTask(
            () => api.WaitBulkInvoices(taskId),
            bulkInvoiceHistoryId => {
                context.commit(actionName, { entityId: bulkInvoiceHistoryId });
            },
            () => {
                context.commit(actionName + 'Failed');
            }
        );
    }
}

export const generalStore: StoreOptions<GeneralState> = {
    state: storeState,
    getters: {
        GetPermissions: state => state.permissions,
        currentPricing: (state): ProductPrice => state.currentPrice,
        currenciesWithoutLocal: (state): Currency[] => state.currencies.filter(x => x.id != state.localCurrencyId),
        currencies: (state): Currency[] => state.currencies,
        currencyRates: (state): CurrencyRate[] => state.currencyRates,
        getProductTypes: (state): ProductType[] => state.productTypes,
        currentLanguage: (state): string => state.lang,
        getNewProduct: (state): Product => {
            if (state.product == null) state.product = new Product();
            return state.product;
        },
        priceTypes: (state): PriceType[] => state.priceTypes,
        product: (state): Product | null => state.product,
        languages: (state): Language[] => state.languages,
        getNewOrder: (state): OrderDetails | null => state.order,
        getNewOrderPriceTypeId: (state): string | undefined => state.order?.priceTypeId,
        getOrderFilter: (state): OrdersFilter | null => state.orderFilter,
        getCouponFilter: (state): CouponFilter | null => state.couponFilter,
        getCurrencySymbol: state => (id: string): string => state.currencies.filter(c => c.id == id)?.[0]?.symbol,
        getDocumentsFilter: (state): DocumentsFilter | null => state.documentsFilter,
        getBusinessAccountingFilter: (state): BusinessAccountingFilter | null => state.businessAccountingFilter,
        getBusinessOrderProductsFilter: (state): BusinessOrderProductsFilter | null => state.businessOrderProductsFilter,
        PriceType: (state): string => state.defaultPriceTypeId,
        countryCode: (state): string => state.countryCode,
        countryLatitude: (state): number => state.countryLatitude,
        countryLongitude: (state): number => state.countryLongitude,
        googleMapsApiKey: (state): string => state.googleMapsApiKey,
        getCurrentDate: (state): Day => state.calendarExactDay,
        getCurrentMonth: (state): Dates => state.calendarExactMonth,
        getSelectedPriceTypeId: (state): string => state.selectedPriceTypeIdFilter,
        getSelectedProductId: (state): string => state.selectedProductIdFilter,
        getCatalogFilter: (state): ProductFilter | null => state.catalogFilter,
        getOrderNotificationsRQ: (state): number => state.ordersNotifications.rqOrdersCount,
        getOrderNotificationsUnreadMessages: (state): number => state.ordersNotifications.unreadMessagesCount,
        getOrderNotificationsCount: (state): number =>
            state.ordersNotifications.rqOrdersCount + state.ordersNotifications.unreadMessagesCount,
        isNotificationOpened: (state): boolean => state.isNotificationMenuExpand,
        canCreateOrders(state): boolean {
            if (state.permissions == null || state.permissions.length == 0) {
                return false;
            }
            return state.permissions.includes(Permissions.CreateOrders);
        },
        getDefaultChannel: (state): string => state.defaultChannelId,
        getBusinessClientsFilter: (state): BusinessClientsFilter | null => state.businessClientsFilter,
        canCancelExistingReceipt: (state): boolean => {
            if (state.permissions == null || state.permissions.length == 0) return false;
            return state.permissions.includes(Permissions.CanCancelExistingReceipt);
        },
        canCreateChangeOrderInPast: (state): boolean => {
            if (state.permissions == null || state.permissions.length == 0) return false;
            return state.permissions.includes(Permissions.CreateChangeOrderInPast);
        },
        canCreateChangeSlotInPast: (state): boolean => {
            if (state.permissions == null || state.permissions.length == 0) return false;
            return state.permissions.includes(Permissions.CreateChangeSlotInPast);
        },
        canChangeUnitPrice: (state): boolean => {
            if (state.permissions == null || state.permissions.length == 0) return false;
            return state.permissions.includes(Permissions.ChangeUnitPrice);
        }
    },
    actions: {
        async loadBusinessClients(context) {
            if (!context.state.businessClients.length) {
                const response = await api.GetAllBusinessClients();
                context.state.businessClients = response.data || [];
            }
            return context.state.businessClients;
        },
        async loadAllLanguages(context) {
            const response = await refDataApi.getAllLangs();
            if (response.data) context.state.languages = response.data;
        },
        async loadAllRegions(context) {
            const response = await refDataApi.getRegions();
            if (response.data) context.state.regions = response.data;
        },
        async getCities(context) {
            if (!context.state.cities.length) {
                const response = await refDataApi.getCities();
                context.state.cities = response.data || [];
            }
            return context.state.cities;
        },
        async loadAllProductTypes(context) {
            const response = await refDataApi.getProductTypes();
            if (response.data) context.state.productTypes = response.data;
        },
        async loadAllTags(context) {
            const response = await refDataApi.getTags();
            if (response.data) context.state.tags = response.data;
        },
        async loadAllSubTags(context) {
            const response = await refDataApi.getSubTags();
            if (response.data) context.state.subTags = response.data;
        },
        async loadAllPredefinedDates(context) {
            const response = await refDataApi.getPredefinedDates();
            if (response.data) context.state.predefinedDates = response.data;
        },
        async getPriceTypes(context) {
            if (!context.state.priceTypes.length) {
                const response = await refDataApi.getPriceTypes();
                context.state.priceTypes = response.data || [];
            }
            return context.state.priceTypes;
        },
        async getMyProducts(context) {
            if (!context.state.myProducts.length) {
                const response = await refDataApi.getMyProducts();
                context.state.myProducts = response.data || [];
            }
            return context.state.myProducts;
        },
        async getProviderCoupons(context) {
            if (!context.state.myCoupons.length) {
                const response = await refDataApi.getProviderCoupons();
                context.state.myCoupons = response.data || [];
            }
            return context.state.myCoupons;
        },
        async getChannels(context) {
            if (!context.state.channels.length) {
                const response = await refDataApi.getChannels();
                context.state.channels = response.data || [];
            }
            return context.state.channels;
        },
        async getSelectedWeekDays(context) {
            const selectedWeekDays = context.state.selectedWeekDays;
            context.state.selectedWeekDays = [];
            return selectedWeekDays;
        },
        async getProductLastOpenedBlock(context) {
            const productLastOpenedBlock = context.state.productLastOpenedBlock;
            context.state.productLastOpenedBlock = 0;
            return productLastOpenedBlock;
        },
        async getBusinessClientTypes(context) {
            if (!context.state.businessClientTypes.length) {
                const response = await refDataApi.getBusinessClientTypes();
                context.state.businessClientTypes = response.data || [];
            }
            return context.state.businessClientTypes;
        },
        async getOfficialClubs(context) {
            if (!context.state.officialClubs.length) {
                const response = await refDataApi.getOfficialClubs();
                context.state.officialClubs = response.data || [];
            }
            return context.state.officialClubs;
        },
        async getCountries(context) {
            if (!context.state.countries.length) {
                const response = await refDataApi.getCountries();
                context.state.countries = response.data || [];
            }
            return context.state.countries;
        },
        async getBanks(context) {
            if (!context.state.banks.length) {
                const response = await refDataApi.getBanks();
                context.state.banks = response.data || [];
            }
            return context.state.banks;
        },
        async getBankBranches(context) {
            if (!context.state.bankBranches.length) {
                const response = await refDataApi.getBankBranches();
                context.state.bankBranches = response.data || [];
            }
            return context.state.bankBranches;
        },
        async loadProviderUsers(context) {
            if (!context.state.users.length) {
                const response = await refDataApi.getProviderUsers();
                context.state.users = response.data || [];
            }
            return context.state.users;
        },
        async getProviderAccounts(context) {
            if (!context.state.providerAccounts.length) {
                const response = await refDataApi.getProviderAccounts();
                context.state.providerAccounts = response.data || [];
            }
            return context.state.providerAccounts;
        },
        async getEmployees(context) {
            if (!context.state.employees.length) {
                const response = await api.getAllEmployees();
                context.state.employees = response.data || [];
            }
            return context.state.employees;
        },
        async loadLeadTypes(context) {
            if (!context.state.users.length) {
                const response = await api.loadLeadTypes();
                context.state.leadTypes = response.data || [];
            }
            return context.state.leadTypes;
        },
        StartCreateBulkInvoicesTask(context, taskId) {
            storeWaitForExportTaskSilent(context, taskId, 'updateCreateBulkInvoicesTask');
        },
        Init(context, data: InitResponse) {
            context.state.permissions = data.permissions;
            context.state.lang = data.lang;
            context.state.isAuthenticated = data.isAuthenticated;
            context.state.currencies = data.currencies;
            context.state.currencyRates = data.currencyRates;
            context.state.googleMapsApiKey = data.googleMapsApiKey;
            context.state.countryCode = data.countryCode;
            context.state.countryLatitude = data.countryLatitude;
            context.state.countryLongitude = data.countryLongitude;
            context.state.productsCounts = data.productsCount;
            context.state.subscriptions = data.subscriptions || [];
            context.state.vatPercent = data.vatPercent;
            context.state.multiplyVAT = data.multiplyVAT;
            context.state.multiplyExcludingVAT = data.multiplyExcludingVAT;
            context.state.defaultChannelId = data.defaultChannelId;
            context.state.envIsProduction = data.envIsProduction;
            context.state.localCurrencyId = data.localCurrencyId;
            context.state.localCurrencySymbol = data.localCurrencySymbol;
            context.state.modalAlerts = data.modalAlerts;
            context.state.defaultProviderSettings = data.defaultProviderSettings;
            if (data.user) {
                context.state.user = data.user;
                const entityId = localStorage.getItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_ENTITY_ID(data.user.id));
                if (entityId) {
                    context.commit('updateCreateBulkInvoicesEntityId', entityId);
                }
                const taskId = localStorage.getItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_TASK_ID(data.user.id));
                if (taskId) {
                    context.commit('updateCreateBulkInvoicesTaskId', taskId);
                    context.dispatch('StartCreateBulkInvoicesTask', taskId);
                }
            }
        }
    },
    mutations: {
        updatePriceType(state, priceTypeId: string) {
            state.defaultPriceTypeId = priceTypeId;
        },
        updateLang(state, lang: string): void {
            state.lang = lang;
        },
        logout(state): void {
            state.isAuthenticated = false;
            state.user = null;
        },
        setProduct(state, product: Product): void {
            state.product = product;
        },
        setOrder(state, order: OrderDetails): void {
            state.order = order;
        },
        setCustomerToChange(state, req: CustomerToChangeDate | null) {
            state.customerToChangeDate = req;
        },
        addProductInOrder(state, orderProduct: OrderProductDetails): void {
            if (!state.order) {
                return;
            }
            const existOrderProductIndex = state.order.products.findIndex(x => x.productId == orderProduct.productId);
            if (existOrderProductIndex >= 0) {
                const existrOrderProduct = state.order.products[existOrderProductIndex];
                orderProduct.customers.forEach(customer => {
                    customer.slot = orderProduct.slot;
                    customer.slotId = orderProduct.slotId;
                    customer.date = `${orderProduct.date}T${orderProduct.slot.startTime}`;
                    customer.couponId = customer.customerInfo.couponId;
                    customer.couponName = customer.customerInfo.coupon?.couponName ?? '';
                    existrOrderProduct.customers.push(customer);
                });
            } else {
                const orderProductNewItem = _.cloneDeep(orderProduct) as OrderProductDetails;
                orderProductNewItem.dateTime = `${orderProduct.date}T${orderProduct.slot.startTime}`;
                orderProductNewItem.customers.forEach(customer => {
                    customer.slot = orderProduct.slot;
                    customer.slotId = orderProduct.slotId;
                    customer.date = `${orderProduct.date}T${orderProduct.slot.startTime}`;
                    customer.couponId = customer.customerInfo.couponId;
                    customer.couponName = customer.customerInfo.coupon?.couponName ?? '';
                });
                state.order?.products.push(orderProductNewItem);
            }
            state.order.newproduct = new OrderProductDetails();
        },
        clearOrderNewProduct(state): void {
            if (state.order) {
                state.order.newproduct = new OrderProductDetails();
            }
        },
        clearOrder(state): void {
            state.order = null;
        },
        saveOrdersFilter(state, filter: OrdersFilter) {
            state.orderFilter = filter;
        },
        saveCouponFilter(state, filter: CouponFilter) {
            state.couponFilter = filter;
        },
        setCurrentPricing(state, newPricing: ProductPrice) {
            const np = newPricing;
            np.productPriceLines = np.productPriceLines.filter(value => value.amount != null);
            state.currentPrice = np;
        },
        updateEditingProductPricing(state, productPrices: ProductPrice[]) {
            if (state.editingProduct) state.editingProduct.productPrices = productPrices;
        },
        saveDocumentsFilter(state, filter: DocumentsFilter) {
            state.documentsFilter = filter;
        },
        saveBusinessAccountingFilter(state, filter: BusinessAccountingFilter) {
            state.businessAccountingFilter = filter;
        },
        saveBusinessOrderProductsFilter(state, filter: BusinessOrderProductsFilter) {
            state.businessOrderProductsFilter = filter;
        },
        saveSelectedWeekDays(state, selectedWeekDays: string[]) {
            state.selectedWeekDays = selectedWeekDays;
        },
        clearCalendarDate(state) {
            if (state.order?.newproduct?.calendarDate) {
                state.order.newproduct.calendarDate = null;
                state.order.newproduct.slotId = '';
                state.order.newproduct.slot = new Slot();
            }
        },
        removeFakeProductPrices(state) {
            if (state.product && state.product.productPrices.length) {
                state.product.productPrices = state.product.productPrices.filter(x => x.id);
            }
        },
        saveProductLastOpenedBlock(state, blockIndex: number) {
            state.productLastOpenedBlock = blockIndex;
        },
        setCurrentMonth(state, currentMonth: Dates) {
            state.calendarExactMonth = currentMonth;
        },
        setCurrentDate(state, currentDay: string) {
            state.calendarExactDay.date = currentDay;
        },
        async setCurrentDay(state, currentDay: Day) {
            state.calendarExactDay = currentDay;
        },
        setPriceTypeIdFilter(state, newPriceTypeId) {
            state.selectedPriceTypeIdFilter = newPriceTypeId;
        },
        setProductIdFilter(state, newProductId) {
            state.selectedProductIdFilter = newProductId;
        },
        updateCatalogFilter(state, newFilter) {
            state.catalogFilter = newFilter;
        },
        setProductRegions(state, regions: ProductRegion[]): void {
            if (state.product && state.product.productRegions) {
                state.product.productRegions = regions;
            }
        },
        setProductTags(state, tags: ProductAllTags): void {
            if (state.product && state.product.productTags) {
                state.product.productTags = tags.productTags;
            }
            if (state.product && state.product.productIncludedTags) {
                state.product.productIncludedTags = tags.productIncludedTags;
            }
            if (state.product && state.product.productExcludedTags) {
                state.product.productExcludedTags = tags.productExcludedTags;
            }
        },
        setOrdersNotifications(state, notifications: OrdersNotifications) {
            state.ordersNotifications = notifications;
        },
        updateNotificationCondition(state, notificationCondition: boolean) {
            state.isNotificationMenuExpand = notificationCondition;
        },
        updateBusinessClientsFilter(state, filter) {
            state.businessClientsFilter = filter;
        },
        updateCurrencyRates(state, currencyRates) {
            state.currencyRates = currencyRates;
        },
        setEmployee(state, employee: Employee) {
            state.employee = employee;
        },
        updateEmployeesFilter(state, newFilter) {
            state.employeesFilter = newFilter;
        },
        updateEmployeesTags(state, tagsForEmployees) {
            state.tagsForEmployees = tagsForEmployees;
        },
        updateEmployeesProducts(state, productsForEmployees) {
            state.productsForEmployees = productsForEmployees;
        },
        updateCreateBulkInvoicesTask(state, { taskId, entityId }) {
            if (!state.user) {
                return;
            }
            state.lastCreateBulkInvoicesTaskId = taskId || null;
            state.lastCreateBulkInvoicesEntityId = entityId || null;
            window.localStorage.setItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_TASK_ID(state.user.id), taskId || '');
            window.localStorage.setItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_ENTITY_ID(state.user.id), entityId || '');
        },
        updateCreateBulkInvoicesEntityId(state, entityId) {
            if (!state.user) {
                return;
            }
            state.lastCreateBulkInvoicesEntityId = entityId;
            window.localStorage.setItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_ENTITY_ID(state.user.id), entityId || '');
        },
        updateCreateBulkInvoicesTaskId(state, taskId) {
            if (!state.user) {
                return;
            }
            state.lastCreateBulkInvoicesTaskId = taskId;
            window.localStorage.setItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_TASK_ID(state.user.id), taskId || '');
        },
        updateCreateBulkInvoicesTaskFailed(state) {
            if (!state.user) {
                return;
            }
            state.lastCreateBulkInvoicesTaskFailed = true;
            state.lastCreateBulkInvoicesTaskId = null;
            state.lastCreateBulkInvoicesEntityId = null;
            window.localStorage.setItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_TASK_ID(state.user.id), '');
            window.localStorage.setItem(LocalStorageKeys.LAST_CREATE_BULK_INVOICE_ENTITY_ID(state.user.id), '');
        },
        removeCreateBulkInvoicesTaskFailed(state) {
            state.lastCreateBulkInvoicesTaskFailed = false;
        },
        saveSubCouponFilter(state, filter: SubCouponsFilter) {
            state.subCouponsFilter = filter;
        },
        showRemindersSnoozePopup(state, open: boolean) {
            state.showRemindersSnoozePopup = open;
        },
        addUserReminders(state, reminders: UserReminder[]) {
            state.userReminders = reminders;
        },
        updateBySnooze(state, data: SnoozeReminderResponse) {
            const userReminder = state.userReminders.find(x => x.id == data.reminderId);
            if (userReminder) {
                userReminder.alertDateTime = data.alertDateTime;
                userReminder.relativeInterval = data.relativeInterval;
            }
        },
        setUserRemindersNotActive(state, reminderIds: string[]) {
            state.userReminders.forEach(element => {
                if (reminderIds.includes(element.id)) {
                    element.active = false;
                }
            });
        },
        setUserReminderNotActive(state, data) {
            const userReminder = state.userReminders.find(x => x.id == data.reminderId);
            if (userReminder) {
                userReminder.active = data.newValue;
            }
        }
    }
};
