import _ from 'lodash';
import moment from 'moment';
import { DayOfWeekToIso } from './Enums';

export class Dates {
    dates: Day[];

    constructor() {
        this.dates = [];
    }
}
export class Day {
    dayOfMonth: number;
    week: number;
    date: string;
    sold: number;
    available: number | null;
    isCurrent: boolean;

    constructor() {
        this.dayOfMonth = 0;
        this.week = 0;
        this.date = '';
        this.sold = 0;
        this.available = 0;
        this.isCurrent = false;
    }
}
export class StopSales {
    stopSaleList: StopSale[];
    constructor() {
        this.stopSaleList = [];
    }
}
export class StopSale {
    groupId: string;
    productsName: string[];
    fromDate: string;
    toDate: string;
    daysOfWeek: number[];
    fromTime: string | null;
    toTime: string | null;

    constructor() {
        this.groupId = '';
        this.productsName = [];
        this.fromDate = '';
        this.toDate = '';
        this.daysOfWeek = [];
        this.fromTime = '';
        this.toTime = '';
    }
}
export class CreateStopSale {
    name: string;
    productIds: string[];
    fromDate: string;
    toDate: string;
    daysOfWeek: number[];
    fromTime: string | null;
    toTime: string | null;

    constructor() {
        this.name = '';
        this.productIds = [];
        this.fromDate = '';
        this.toDate = '';
        this.daysOfWeek = [];
        this.fromTime = '';
        this.toTime = '';
    }
}
export class DayData {
    startTimes: string[];
    products: ProductData[];

    constructor() {
        this.startTimes = [];
        this.products = [];
    }
}
export class ProductData {
    color: string;
    productId: number;
    quantity: number;
    active: boolean;
    slots: Slot[];

    constructor() {
        this.color = '';
        this.productId = 0;
        this.quantity = 0;
        this.active = true;
        this.slots = [];
    }
}
export class Slot {
    available: number;
    columnShift: number;
    durationMinutes: number;
    quantity: number;
    slotId: number;
    sold: number;
    startTime: string;

    constructor() {
        this.available = 0;
        this.columnShift = 0;
        this.durationMinutes = 0;
        this.quantity = 0;
        this.slotId = 0;
        this.sold = 0;
        this.startTime = '';
    }
}

export function getTime(str: string) {
    if (!str) return str;
    const [h, m] = str.split(':');
    return (h.length == 1 ? '0' : '') + h + ':' + m;
}
export class CalendarSlot {
    id = '';
    productId = '';
    originalSlotId = '';
    limit: number | null = null;
    startDate: Date | string = new Date();
    duration = '';
    startTime = '';
    endTime = '';
    daysOfWeek: string[] = [];
    repeatStops: CalendarStopSale[] = [];
    repeatMode = 1;
    repeatEndDate: Date | string | null = '';
    repeatCount: number | null = null;
    blockCreatingNewOrdersBefore = false;
    orderBlockMinutes: number | null = 0;
    extraInfo = '';
    forGroups = false;
    forIndividuals = true;
    forGroupsDisabled = false;
    forIndividualsDisabled = false;
    isVirtual = false;
    connection: boolean | null = null;
    allowAssignEmployees = false;
    employeeIds: string[] = [];
    customerGroupName: string | null = null;
    customerName: string | null = null;

    static fromKendoSlot(slot: CalendarSlotKendo, editAll: boolean) {
        const slotApi = slot.apiSlot ? CalendarSlot.clone(slot.apiSlot) : new CalendarSlot();
        const start = moment(slot.start, moment.ISO_8601);
        const end = moment(slot.end, moment.ISO_8601);
        slotApi.limit = slot.limit;
        slotApi.productId = slot.productId;
        slotApi.startDate = (editAll && slot.apiSlot?.startDate) || start.format('YYYY-MM-DD');
        slotApi.startTime = start.format('HH:mm');
        slotApi.endTime = end.format('HH:mm');
        slotApi.duration = slotApi.duration || moment.utc(end.diff(start)).format('HH:mm');
        slotApi.repeatMode = slotApi.repeatMode || 1;

        if (!editAll) {     
            slotApi.originalSlotId = slotApi.id;
            slotApi.id = slot.slotId;                       
            slotApi.repeatMode = 1;
            slotApi.repeatCount = null;
            slotApi.repeatEndDate = null;
        }

        return slotApi;
    }

    static clone(s: CalendarSlot): CalendarSlot {
        return {
            id: s.id,
            productId: s.productId,
            limit: s.limit,
            startDate: s.startDate ? moment(s.startDate, moment.ISO_8601).toDate() : ((null as any) as string),
            startTime: getTime(s.startTime),
            endTime: getTime(s.endTime),
            duration: getTime(s.duration),
            daysOfWeek: s.daysOfWeek,
            repeatStops: _.clone(s.repeatStops),
            originalSlotId: s.originalSlotId,
            repeatCount: s.repeatCount,
            repeatEndDate: s.repeatEndDate ? moment(s.repeatEndDate, moment.ISO_8601).toDate() : '',
            repeatMode: s.repeatMode,
            orderBlockMinutes: s.orderBlockMinutes,
            blockCreatingNewOrdersBefore: s.blockCreatingNewOrdersBefore,
            extraInfo: s.extraInfo,
            forGroups: s.forGroups,
            forIndividuals: s.forIndividuals,
            forGroupsDisabled: s.forGroupsDisabled,
            forIndividualsDisabled: s.forIndividualsDisabled,
            isVirtual: s.isVirtual,
            connection: s.connection,
            allowAssignEmployees: s.allowAssignEmployees,
            employeeIds: s.employeeIds,
            customerGroupName: s.customerGroupName,
            customerName: s.customerName
        };
    }
}

export class CalendarEvent {
    id = '';
    originalSlotId = '';
    name = '';
    description = '';
    startDate: Date | string = new Date();
    startTime = '';
    endDate: Date | string = new Date();
    endTime = '';

    static fromKendoSlot(slot: CalendarSlotKendo) {
        const slotApi = slot.apiEvent ? CalendarEvent.clone(slot.apiEvent) : new CalendarEvent();

        return slotApi;
    }

    static clone(s: CalendarEvent): CalendarEvent {
        return {
            id: s.id,
            name: s.name,
            description: s.description,
            startDate: moment(s.startDate, moment.ISO_8601).toDate(),
            startTime: getTime(s.startTime),
            endDate: moment(s.endDate, moment.ISO_8601).toDate(),
            endTime: getTime(s.endTime),
            originalSlotId: s.originalSlotId
        };
    }
}

export class CalendarStopSale {
    id = '';
    slotId = '';
    isChange = false;
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    daysOfWeek: string[] = [];
    productId = '';

    static clone(s: CalendarStopSale): CalendarStopSale {
        return {
            id: s.id,
            isChange: s.isChange,
            slotId: s.slotId,
            startDate: moment(s.startDate, moment.ISO_8601).toDate(),
            endDate: moment(s.endDate, moment.ISO_8601).toDate(),
            daysOfWeek: _.clone(s.daysOfWeek),
            productId: s.productId
        };
    }
}

export enum RepeatMode {
    None = 1,
    DAILY = 2,
    WEEKLY = 3
}
export class CalendarSlotKendo {
    id = '';
    productId = '';
    slotId = '';
    end = new Date();
    start = new Date();
    startTime = '';
    endTime = '';
    recurrenceId: string | null = null;
    recurrenceRule = '';
    recurrenceException = '';
    limit: number | null = null;
    type = '';
    isVirtual = false;

    apiSlot: CalendarSlot | null = null;
    apiEvent: CalendarEvent | null = null;

    static fromAvailability(sold: CalendarSlotAvailability) {
        const slot = new CalendarSlotKendo();
        const start = moment(sold.date, moment.ISO_8601);
        slot.start = start.toDate();
        slot.end = moment(sold.endDate, moment.ISO_8601).toDate();
        slot.limit = sold.sold;
        slot.productId = sold.productId;
        slot.id = sold.slotId + '||' + start.toISOString();
        slot.slotId = sold.slotId;
        slot.isVirtual = sold.isVirtual;
        return slot;
    }

    static fromSlot(slot: CalendarSlot) {
        const calendarItem = new CalendarSlotKendo();
        calendarItem.id = slot.id;
        calendarItem.productId = slot.productId;
        calendarItem.limit = slot.limit;
        const startTime = moment.duration(slot.startTime);
        const startDate = moment(slot.startDate, moment.ISO_8601)
            .startOf('day')
            // Need to set the start time manualy by minutes and hours because of DST (Daylight Saving Time) issue
            .set('minutes', startTime.minutes())
            .set('hours', startTime.hours());
        const endTime = startTime.clone().add(moment.duration(slot.duration));

        calendarItem.start = startDate.toDate();
        calendarItem.end = startDate
            .clone()
            .add(endTime.days(), 'days')
            .set('minutes', endTime.minutes())
            .set('hours', endTime.hours())
            .toDate();

        const repeatEnd = slot.repeatCount
            ? `COUNT=${slot.repeatCount}`
            : slot.repeatEndDate
            ? `UNTIL=${moment(slot.repeatEndDate)
                  .endOf('day')
                  .toISOString()}`
            : '';
        switch (slot.repeatMode as RepeatMode) {
            case RepeatMode.DAILY:
                calendarItem.recurrenceRule = `FREQ=DAILY;BYDAY=${slot.daysOfWeek};` + repeatEnd;
                break;
            case RepeatMode.WEEKLY:
                calendarItem.recurrenceRule = `FREQ=WEEKLY;BYDAY=${slot.daysOfWeek};` + repeatEnd;
                break;
        }
        calendarItem.recurrenceException = _.chain(slot.repeatStops)
            .map(x => {
                let start = moment(x.startDate, moment.ISO_8601);
                const days = x.daysOfWeek.map(d => DayOfWeekToIso[d]);
                const end = moment(x.endDate, moment.ISO_8601).endOf('day');
                const list: string[] = [];
                while (start <= end) {
                    if (days.includes(start.isoWeekday())) {
                        list.push(
                            // Need to set the start time manualy by minutes and hours every day because of DST (Daylight Saving Time)
                            start
                                .clone()
                                .startOf('day')
                                .set('minutes', startTime.minutes())
                                .set('hours', startTime.hours())
                                .toISOString()
                        );
                    }
                    start = start.add({ day: 1 });
                }
                return list;
            })
            .flatten()
            .uniq()
            .value()
            .join(',');
        /*
        calendarItem.recurrenceException = slot.repeatStops
            .map(x =>
                moment(x, moment.ISO_8601)
                    .add(startMinutes, 'minutes')
                    .toDate()
                    .toISOString()
            )
            .join(',');
            */

        calendarItem.apiSlot = slot;
        calendarItem.slotId = slot.id;
        calendarItem.type = 'slot';
        calendarItem.isVirtual = slot.isVirtual;
        return calendarItem;
    }

    static fromEvent(event: CalendarEvent) {
        const calendarItem = new CalendarSlotKendo();
        calendarItem.id = event.id;
        const startDate = moment(event.startDate, moment.ISO_8601);
        const endDate = moment(event.endDate, moment.ISO_8601);
        const startMinutes = moment.duration(event.startTime).asMinutes();
        const endMinutes = moment.duration(event.endTime).asMinutes();

        calendarItem.start = startDate
            .clone()
            .add(startMinutes, 'minute')
            .toDate();
        calendarItem.end = endDate
            .clone()
            .add(endMinutes, 'minute')
            .toDate();

        calendarItem.apiEvent = event;
        calendarItem.type = 'event';
        return calendarItem;
    }
}
export type CalendarSchedulerEvent = CalendarSlotKendo & kendo.data.SchedulerEvent;

export class CalendarSlotAvailability {
    productId = '';
    slotId = '';
    isVirtual = false;
    date = new Date();
    endDate: Date | null = null;
    sold = 0;
    limit?: number | null = null;
    originalSlotId = '';
}
