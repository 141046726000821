import { ProductTags } from '@/models/Product';
import { IdName } from '@/models/Interfaces';

export class EmployeeFilter {
    schedules: EmployeeSchedule[];
    isTagsFilterTypeAnd: boolean;
    tagIds: string[];
    keyword: string;
    status: boolean;

    constructor() {
        this.schedules = [];
        this.isTagsFilterTypeAnd = false;
        this.tagIds = [];
        this.keyword = '';
        this.status = true;
    }
}

export class Employee {
    id: string;
    idNoHash: number;
    providerId: string;
    pid: string | null;
    employeeNumber: number | null;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    mobile: string;
    address: string;
    status: boolean;
    ratePerHour: number | null;
    currencyId: string;
    qtySlotsLastMonth: number;

    tagNames: string[];
    tags: IdName[];
    products: IdName[];
    employeeSchedules: EmployeeSchedule[];
    noAssignmentsCurrentMonth: boolean;
    noAssignmentsCurrentMonthByOrder: boolean;
    assignedOrderSlot: boolean;

    constructor() {
        this.id = '';
        this.idNoHash = 0;
        this.providerId = '';
        this.pid = null;
        this.employeeNumber = null;
        this.firstName = '';
        this.lastName = '';
        this.fullName = '';
        this.email = '';
        this.mobile = '';
        this.address = '';
        this.status = true;
        this.ratePerHour = null;
        this.currencyId = '';
        this.qtySlotsLastMonth = 0;

        this.tagNames = [];
        this.tags = [];
        this.products = [];
        this.employeeSchedules = [];
        this.noAssignmentsCurrentMonth = false;
        this.noAssignmentsCurrentMonthByOrder = false;
        this.assignedOrderSlot = false;
    }
}

export class EmployeeSchedule {
    dayOfWeek = 0;
    startTime = '';
    endTime = '';
    active = false;
}

export class SlotsWithNoEmployeeFilter {
    productIds: string[];
    startDate: string | null;
    endDate: string | null;
    pageIndex: number;
    orderBy: string;
    asc: boolean;
    withEmployees: boolean;

    constructor() {
        this.productIds = [];
        this.startDate = null;
        this.endDate = null;
        this.pageIndex = 0;
        this.orderBy = 'StartDate';
        this.asc = false;
        this.withEmployees = true;
    }
}

export class ExportEmployeesSlots extends SlotsWithNoEmployeeFilter {}
export class SlotsWithNoEmployee {
    id = '';
    startDate = '';
    productName = '';
    productId = '';
    endDate = '';
    startTime = '';
    duration = '';
    daysOfWeek = [];
}

export class CheckAssignEmployeeResponse {
    isAssignedInPast = false;
    isAssignInFuture = false;
}

export class AssignedEmployeesResponse {
    assignedEmployees: AssignedEmployee[] = [];
}

export class AssignedEmployee {
    slotId = '';
    startDate: string | null = '';
}
