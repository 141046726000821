
import { generalStore } from '@/store';

export default {
    setup() {
        const body = document.querySelector('body');
        body?.setAttribute('dir', generalStore.getters.currentLanguage === 'he-IL' ? 'rtl' : 'ltr');

        return {
            generalStore
        };
    }
};
