import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@vueform/multiselect/themes/default.css';
import '@/assets/base.scss';
import '@/assets/icons.scss';
import 'primevue/resources/themes/saga-blue/theme.css';
import '@/assets/accounting.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import 'jquery/dist/jquery.min.js';

import '@progress/kendo-ui/js/kendo.all'; // This will import the entire Kendo UI library
// As an alternative, you could import only the scripts that are used by a specific widget:
// import '@progress/kendo-ui/js/kendo.scheduler' // Imports only the Scheduler script and its dependencies

import '@/assets/kendo.scss';

import { createApp } from 'vue';

import App from '@/App.vue';
import router from '@/router';
import { api } from '@/services/Api';
import { generalStore } from '@/store';
import { createI18n } from 'vue-i18n';
import mitt from 'mitt';
const emitter = mitt();
import en from '@/lang/en-US.json';
import ru from '@/lang/ru-RU.json';
import he from '@/lang/he-IL.json';
import PrimeVue from 'primevue/config';
import mixin from '@/mixin';
import addKendoLocale from './services/kendo.locales';
import VersionPolling from '@/plugins/VersionPolling';
import Tooltip from 'primevue/tooltip';

function setDir(lang: string) {
    const body = document.querySelector('body');
    const cls = lang == 'he-IL' ? 'rtl' : 'ltr';
    body?.classList.add(cls);
}

(async (): Promise<void> => {
    const initResponse = await api.init();
    if (initResponse.data) {
        generalStore.dispatch('Init', initResponse.data);
        generalStore.commit('updateLang', initResponse.data.lang);
        generalStore.commit('updatePriceType', initResponse.data.defaultPriceTypeId);
    }
    setDir(initResponse.data?.lang || 'en-US');

    const i18n = createI18n({
        locale: initResponse.data?.lang,
        legacy: false,
        globalInjection: true,
        fallbackLocale: 'en-US',
        messages: {
            'en-US': en,
            'he-IL': he,
            'ru-RU': ru
        }
    });
    addKendoLocale(i18n.global);

    const app = createApp(App);

    app.mixin({
        mixins: [mixin]
    });
    app.config.globalProperties.$filters = {
        formatLocalDateTime: mixin.methods.formatLocalDateTime
    };

    app.provide('emitter', emitter);
    app.use(i18n);
    app.use(generalStore);
    app.use(router);
    app.use(PrimeVue);
    app.use(VersionPolling);
    app.directive('tooltip', Tooltip);
    app.mount('#app');
})();
